import React from 'react';
import logo from './logo.svg';
import './footer.css';

function Footer() {
  return (
    <footer className="footer">

      <p><span className="strong">email:</span> marketing[at]argon.sh</p>
    </footer>
  );
}

export default Footer;
